/* .container {
    th[class*="ant-table-cell"] {
      padding: 8px 5px 8px 5px !important;
      height: 16px !important;
      line-height: 20px !important;
      text-align: center !important;
      font-stretch: expanded !important;
      background-color: #fff !important;
      border-radius: 0px !important;
    }
    td[class*="ant-table-cell"] {
      padding: 3px 1px 3px 1px !important;
      text-align: center;
      font-stretch: expanded !important;
      font-family: Arial, Helvetica, sans-serif;
      border: none;
    }
    thead[class*="antd-table-thead"] {
      padding: 0px;
      border-radius: 0px !important;
      border: none;
      border-color: #fff !important;
      text-align: center;
    }
    tr[class*="antd-table-thead"] {
      padding: 0px;
      border: none;
      border-color: #fff !important;
      text-align: center;
    }
    tr[class*="antd-table-row"] {
      padding: 0px !important;
      border: none !important;
      border-color: #fff !important;
      text-align: center !important;
    }
    tr[class*="antd-table-row"] {
      padding: 0px !important;
      border: none !important;
      border-color: #fff !important;
      text-align: center !important;
    }
    th[class*="antd-table-thead"] {
      padding: 0px;
      border: none;
      border-color: #fff !important;
      text-align: center;
    }
    tr[class*="antd-table-body"] {
      padding: 0px;
      border-color: #fff !important;
      text-align: center;
    }
    th[class*="antd-table-body"] {
      padding: 0px;
      border-color: #fff !important;
      text-align: center;
    }
    div[class*="ant-table-header"] {
      border-radius: 0px !important;
      border-color: #fff !important;
      padding: 0px !important;
      border: none !important;
    }
    tr[class*="ant-table-header"] {
      border-radius: 0px !important;
      border-color: #fff !important;
      padding: 0px !important;
      border: none !important;
    }
    thead[class*="ant-table-thead"] > tr > th {
      border: none;
      ::before{
        background-color: #fff;
      }
    }
    thead[class*="ant-table-thead"] > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      border: none;
     
        background-color: #fff;
     
    }
    tbody[class*="ant-table-tbody"] > tr > td {
      border-color: gray;
    }
    span[class*='ant-table-column-sorter']{
      display: none;
    }
  }
  .customTable .ant-table-thead > tr > th {
    border: none;
    border-color: #fff !important;
    border-radius: 0px !important;
  } */

.customStyleselect {
  background-color: red !important;
}

.light-row,
.light-row:hover {
  background-color: #e8e8e8 !important;
}
.dark-row,
.dark-row:hover {
  background-color: #f5f5f5 !important;
}

.ant-table-cell-row-hover {
  background-color: inherit !important;
}

.menu-icons {
  font-size: 20px;
}

.msrp-column {
  color: #6134eb !important;
}

.wholesale-column {
  color: chocolate !important;
}
